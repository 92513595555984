*{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  direction: rtl;
  margin: 0;
  padding: 0;
}
body{
  height: 100vh;
}
.app{
  background-image: url("../../public/background.jpg");
  background-repeat: repeat;
  
  display: grid;
  grid-template-columns: repeat(7,1fr);
  color: white;
height: 100vh;
overflow: hidden;
background: #141422;
}
@media (max-width:768px){
  .app{
    height: auto;
    display: flex;
flex-direction: column;
 }
}
.rightbar{
grid-column: 1/3;
  display: flex;
grid-template-columns: repeat(3,1fr);
  gap: 1px;
  font-size: 12px;
 height: 100vh;
 border: 1px solid #33323e;
 border-top: none;
}
.rightbar >div:first-child {
  width: 20% !important;
}
.rightbar > .component{
  width: 40%; 
}
.button {
  background-color: #321D2E;
  color: white;
  width: fit-content;
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: 13px;
}
.button.upper{
  background: #181622;
  width: 25%;
  border: 1px solid #33323e;
  font-size: 12px;

}
.button.upper.active{
  background: #321D2E !important;
}

.upperButtonFather{
  display: flex;
  gap: 4px;
  width: 100%;
  right: 25px;
  margin-top: 2px;
  margin-bottom: 1px;
  flex-direction: row;
}
.upperButtonFather.write{
  padding: 10px;
  justify-content: center;
}
.upperButtonFather.write .button.upper{
  background: #191620;
  width: 18%;
  border: 1px solid #33323e;
  font-size: 14px;
  text-align: center;
}
@media (max-width:768px){
  .rightbar{
    grid-template-columns: repeat(2,1fr);
  }
}
@media (max-width:480px){
  .rightbar{
    grid-template-columns: 1fr;
  }
}
.component{
  display: flex;
  flex-direction: column;
/* justify-content: space-between; */
}

.inner-component{
  height: 48%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.inner-component .header-two-span{
  height: 48%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}
.header-two-span span{
  justify-content: center;
}
.inner-component img{
  width: 22px;
  height: 22px;
}
.component > header{
  display: flex;
    justify-content: space-between;
    background-color: #3D2E4F;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 1px;
    font-size: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
.component > header span{
  display: flex;
  align-items: center;
  width: 100%;
}
.component > header> span:first-of-type{
  color: yellow;
  font-size: 16px;
  font-weight: bold;
}
.component > header > span>span{
  color: white;
  margin-right: 2px;
}
 .component > header > span>.icon{
color: yellow;
cursor: pointer;
  }

.component .component-content{
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 45vh;
  overflow-y: scroll;
}
.component .component-content >div{
  display: flex;
gap: 1px;
}
.component .component-content >div span,.component .component-content >div a{
  color: white;
    text-decoration: none;
    padding: 3px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background-color: #161523;
    margin-bottom: 3px;
    border: 1px solid #33323e;
}
.component .component-content >div .stat{

  width: fit-content;
  background: #D9D9D9;
  color: black;
  width: 33px;
  text-align: center;
  /* height: 44px; */
  display: flex;
  font-weight: bold;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.component .component-content >div .title{
  margin-left: 0;
  width: 100%;
}
.blue{
  background:#3D2E4F !important;
  color: white !important;
}

.main-content{
  grid-column: 3/7;
  display: flex;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10,1fr);
  height: 100vh;
  flex-direction: column;
  padding-right: 5px;
  padding-left: 5px;
}
.upper-div{
  display: flex;
  flex-direction: column;
  height: 71%;

}
.upper-div.grand{
  display: flex;
    flex-direction: column;
    height: 92%;
}
.down-father{
  height: fit-content;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-bottom: 2px;
  column-gap: 4px;
  flex-wrap: wrap;
  
}
.grand.desc{
  height: 79%;
}
.upper-div header{
  width: 100%;
  display: flex;
  gap: 8px;
  height: 110px;
  padding-left: 13px;
  border: 1px solid #33323e;
  border-radius: 7px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-right: 5px;
  padding-top: 2px;
  background: #321D2E;
}
.upper-div header>div{
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.upper-div header>img{
  height: 100%;
  width: 15%;
}



.upper-div .row span{
  background-color: rgb(224, 107, 107);
  padding: 3px 0;
  border-radius: 3px;
  display: block;
  height: fit-content;
  flex-grow: 1;
  cursor: pointer;
  text-align: center;
}
.upper-div .column{
  display: flex;
  flex-direction: column;
  gap: 3px;
  column-gap: 3px;
}
.upper-div .column span:not(:first){
  margin: 1.5px 0;
}
.upper-div .rowWrite{
  display: flex;
  flex-direction: row;
  gap: 9px;
  padding-bottom: 10px;
  border-bottom: 1px solid #817B7B;
}
.upper-div .rowWrite .row-button-item {
  cursor: pointer;
}
.upper-div .height{
  padding: 8px 2px;
  display: flex;
  gap: 3px;
  height: 50%;

}
.upper-div .height span{
  width: 100%;
}
.upper-div .input{
direction: ltr;
  display: flex;
  width: 25%;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.upper-div .input.keywoard{
  width: 30%;
}
.input.title{
  width: 87%;
    margin-left: 2%;
    height: 100%;
    width: 50%;
}
.upper-div .input input{
  padding-right: 20px;
  direction: rtl;
  font-size: 12px;
  padding-left: 3px;
  border: none;
  width: 100%;
  height: 100%;
  background: black;
  border: 1px solid #33323e;
  outline: none;
  color: white;
}
.upper-div .input input:focus::placeholder{
  opacity: 0;
  transition: .3s;
}
.upper-div .input button {
  top: 0;
  right: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  height: 100%;
  color: #6931a2;
  font-size: 15px;
  position: absolute;
  background-color: black;
  transition: color 500ms;
  border: 1px solid #33323e;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  
}
.upper-div .content{
    border-radius: 3px;
    grid-row: 2/10;
    color: white;
    display: flex;
    flex-direction: column;
    height: 80%;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid #321D2E;
    background: #141422;
}
.ql-snow *{
  direction: ltr;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before,
.ql-snow .ql-picker.ql-font .ql-picker-label::before, .ql-snow .ql-picker.ql-font .ql-picker-item::before
{
  float: left;
}
.ql-container{
  overflow: hidden;
}
.ql-editor{
  overflow-y: auto;
}
.upper-div .tableau{
grid-row: 10/11;
  gap: 10px;
}
.upper-div .tableau {
  display: none;
  text-align: center;
  padding: 3px 10px;
  background-color: #3c5475;
    border-radius: 3px;
border-top: 1px solid white;
border-bottom: 1px solid white;}
aside{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*aside .score-indicator{
  grid-row: 1/2;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px 0;
min-height: fit-content;
}
aside .score-indicator>div:nth-child(2){
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: auto;
}
aside .score-indicator>div:nth-child(3){
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: rgb(182, 182, 182);
}

aside .score-indicator>div>div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
aside .score-indicator h3{
  font-size: 20px;
}
aside .score-indicator h5{
  font-size: 13px;
  color: rgb(182, 182, 182);
}*/
aside  .score-write-container{
  display: flex;
  width: 221px;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;

}
aside .paragraphe{
  grid-row: 2/4;
  padding: 4px;
  border-radius: 3px;
  background-color: #181622;
  font-size: 12px;
  border: 1px solid #33323e;
  height: 50%;
  max-height: 51vh;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;

}
aside .paragraphe p{
  min-height: 79%;
  overflow-y: scroll;
  padding: 4px;
}
aside .arrows{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: #3c5475; */
  color: #321D2E;
  font-weight: bold;
  font-size: 15px;
  padding: 2px;
  height: 7%;
  padding-right: 5%;
    padding-left: 5%;

}
aside .arrows svg{
cursor: pointer;
}
.lower-father-div{
  display: flex;
  grid-template-columns: 1fr -10fr;
  gap: 3px;
  grid-row: 7/11;
  flex-direction: column;
  height: 27%;
  margin-top: 8px;
  padding: 3px;
  border-radius: 7px;
  border: 1px solid #33323e;
  background: #161523;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.lower-div{
  display: flex;
  grid-template-columns: 1fr -10fr;
  gap: 3px;
  grid-row: 7/11;
  flex-direction: row;
  height: 85%;
  margin-top: 1px;
  padding: 3px;
  border-radius: 7px;
}
.lower-div.three{
  overflow-y: scroll;
}
.lower-div .box{
  background-color: black;
  border-radius: 3px;
  border: 3px solid #161523;
  width: 49%;
}
.lower-div .box:first-child{
  border-left: none;
  /*width: 50%;
  height: 90%;
  display: grid;*/
    flex-direction: column;
    grid-template-rows: repeat(2,1fr);
}
.lower-div .box{
  border-right: none;
  width: 50%;
  height: 92.5%;
}
#root > div > main > div.lower-div > div > div{
  width: 100%;
  height: 100%;
}
#root > div > main > div.lower-div > div > div > div > div:nth-child(1) > div{
  position: relative;
  width: 100%;
  height: 100%;

}
.scene-container{
  width: 100% !important;
  height: 100%!important; 
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
.box.write  >div{
  height: 100%;
}

#root > div > main > div.lower-father-div >div.lower-div > div:nth-child(1) > div > div > div:nth-child(1) > div > canvas{
  width: 100% !important;
  height: 100%!important; 

}
#root > div > main > div.lower-father-div >div.lower-div > div > div,
#root > div > main > div.lower-father-div > div.lower-div > div > div * {
  height: 97% !important;
  width: 100% !important;
}
.select-write-bottom{
  background-color: #321D2E;
  width: 100%;
  height: 30px;
  color: white;
  border: white;
  /* padding: 5px; */
  /* margin-bottom: 2px; */
  font: inherit;
  cursor: pointer;
  display: block;
  width: 100%;
  grid-row: 1/2;
}
.box >div {
  grid-row: 2/3;
    height: 80%;
    display: flex;
}
.box >div >div{
  position: relative;
    height: 100%;
    display: flex;
    width: 100%;
}
.box >div >div >div{
  width: 100%;
  height: 100%;
}
.refrehs-button{
  background-color: #321D2E;
    width: 100%;
    height: 30px;
    color: inherit;
    border: none;
    margin: 0;
    font: inherit;
    /* font-size: 3vh; */
    cursor: pointer;
    display: block;
    padding-bottom: -29%;
}
 .stats{
    display: flex;
    grid-row: 8/11;
    background-color: #141422;
    font-size: 12px;
    border: 1px solid #817B7B;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 8px;
    padding-right: 1px;
    margin-bottom: 50px;
    margin-left: 5px;
    width: 90%;
}
 .stats > header{
  background-color: #161523;
  display: flex;
}
 .stats>div{
  height: 90%;
    margin-top: 3px;
    padding: 8px 5px;
    /*display: grid;*/
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4,1fr);
}
.stats>div>div{
  width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
 .stats >div>div>div{
  color: white;
    display: flex;
    justify-content: space-around;
    margin: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #000000;
    
    height: 29px;
    border-radius: 2px;
    border: 1px solid #817B7B;
}
.stats svg{
  height: 19px;
    width: 19px;
}
.stats span.title {
  display: flex;
    align-items: center;
}
@media (max-width:1024px){
  .upper-div {
    display: flex;
    flex-direction: column;
  }
  .upper-div > div,.upper-div > aside{
    width: 100%;
  }
}


/* Leftbar */

.leftbar{
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10,1fr);
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  min-width: 245px;
}





/* Dropdown */
.dropdown {
  position: relative;
    color: white;
    font-size: 12px;
    display: flex;
  
}
.dropdown button{
  color: white;
  background-color: #321D2E  ; 
  border: none;
  border-radius: 3px;
  padding: 7px;
  cursor: pointer;
  height: fit-content;
  width: 100%;
  border: 1px solid #817B7B 
}
.dropdown> button{
  display: flex;
    justify-content: space-between;
    gap: 7px;
    align-items: center;

}
.row-button-item {
  padding: 0px 1%;
    border-radius: 5px;
    border: 1px solid #817B7B;
    background: #321D2E;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    min-width: 55px;
}
.menu {
  position: absolute;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  width: 100%;
  z-index: 300;
}

.menu > li {
  margin:1.5px 0;
}


.menu > li > button {
  background-color: rgb(224, 107, 107);
  width: 100%;
  height: 100%;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  display: block;
}
/* End dropdown */
/*my change*/
.rtl-input {
  direction: rtl;
  text-align: right;
}

.title-snippte{
  color: white;
  text-decoration: none;
  padding: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #3c5475;
  width: 100%;
    cursor: pointer;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #1976d2;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.loader_mid {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(224, 107, 107);
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.inside_model{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inside_paragraph{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pre_head{
  min-height: 7%;
  /* background: #3c5475; */
  margin-bottom: 4px;
  /* color: #bf93cb; */
  background: #3D2E4F;
  /* border: 2px solid #6931a2; */
  text-align: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  width: 100%;

}
.background-container {
  background-color: black;
  position: relative;
}

.background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(white 5%, transparent 0);
  background-size: 20px 20px;
  pointer-events: none;
}

.arrow_div{
  height: 3px;
}
.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid #321D2E !important;
  background-color: #141422;
  }
.ql-container.ql-snow {
    border: none;
}
.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #8e8d98;
}
.ql-snow .ql-toolbar.snow, .ql-snow .ql-stroke {
  stroke: #8e8d98;}

.ql-snow .ql-picker {
  color: #8e8d98;}
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    display: flex;
    flex-direction: column;}

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      /* border-color: white; */
      border: none;
      border-radius: 2px;
      background: black;
      color: #8e8d98;
      margin-top: 2px;
  }
input::placeholder {
    color: #8e8d98;
}
.graph-info-msg {
  display: none;}

.exit{
  
  height: 30px;
  width: fit-content  ;/*
  transform: rotate(180deg);*/
  cursor: pointer;
  display: flex;
  align-items: center;
}
.grande {
  height: 30px;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    }

.small_container{
  display: flex;
  gap: 4px;
  flex-direction: row;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 8px;
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 7px;
  /* border: 1px solid #33323e; */
  background: #321D2E;
  justify-content: space-between;
  font-size: 0.7em;
  align-items: center;

}
.metaDescreption{
  background-color: #181622;
  font-size: 12px;
  border: 1px solid #33323e;
  color: white;
  width: 100%;
  height: 67%;
  resize: none;
  outline: none;
}

.down-table-three{
  width: 100%;
  border-spacing: 0px;
}
.down-table-three th{
  border: 1px solid #33323e;
}
.down-table-three td{
  border: 1px solid #33323e;
  border-top: none;
}
.down-table-three td[colspan="1"] {
  /* Your styles for td elements with colspan="1" */
  width: 25px;
  text-align: center;
}
.down-table-three .head-table-three{
  border: 1px solid #817B7B;
    display: flex;
    width: 100%;
}
.down-table-three .head-table-three > div{
  width: 50%;
   
    display: flex;
    justify-content: center;
    align-items: center;

}
.down-table-three .head-table-three > div:first-child{
  border-left: 1px solid #817B7B;

}
.down-table-three .body-table-three{
  border-bottom: 1px solid #817B7B;
  border-right: 1px solid #817B7B;
  border-left: 1px solid #817B7B;
  display: flex;
  width: 100%;
  flex-direction: row;

}
.down-table-three .body-table-three .body-table-three-body {

  width: 50%;

}
.down-table-three .body-table-three .body-table-three-body:first-child{
  border-left: 1px solid #817B7B;

}
.down-table-three .body-table-three-colum{
  display: flex;
    
    border-bottom: 1px solid #817B7B;
    justify-content: space-between;
}
.down-table-three .body-table-three-colum > div:nth-child(2){
  border-right: 1px solid #817B7B;
  width: 30px;
  text-align: center;
}
.down-table-three .body-table-three-colum > div{
  padding: 3px;
}
@media(max-width:768px){
  .upper-div header{
  display: flex;
  flex-direction: column;
}
.row{
  flex-wrap: wrap;
}
}

.nich{
  display: flex;
    flex-direction: row;
    width: 90%;
    border: 1px solid #817B7B;
    padding-top: 4px;
    padding-bottom: 4px;
    /* height: 33px; */
    /* padding: 1px; */
    font-size: 14px;
    justify-content: center;
    margin-bottom: 4px;
}
.nich >div{
  display: flex;
  gap: 7px;
  width: 49%;
  font-size: 13px;
}
.bottom-bar{
  font-size: 12px;
  border-radius: 3px;
  min-height: fit-content;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: 0px 0px 2px 0px;
  border: 1px solid #321D2E;
  height: 33px;
  width: 100%;
  border-top: none;
  margin: 0px;
  padding: 0px;
  background: #141422;
  color: #D9D9D9;
  display: flex;
  flex-direction: row;
}
.head-state{
  width: 90%;
}
.StateSconde{
  width: 100%;
  margin-top: -20px;
}
.bottom-one{
  width: 70%;
    display: flex;
    justify-content: flex-start;
    height: 100%;
}
.bottom-one .bar-item {
  border-left: 1px #321D2E solid;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
}
.bottom-two{
  width: 30%;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    min-width: fit-content;
}
.bottom-two .bar-item {
  border-right: 1px #321D2E solid;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  min-width: fit-content;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.head-snippte{
  width: 97%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-content: center;
  flex-wrap: wrap;
}
.select__menu{
  background-color: #000000 !important;
  color:white
}
.basic-single.css-b62m3t-container{
  width: 18%;
  border: none !important;
  max-width: 60px;
}
.select__indicator {
  padding: 0px !important;
}
.select__value-container{
  padding: 0px !important;
  padding-right: 5px !important;
  
}
.select__single-value{
  color: white !important;
}
.select__input-container{
  color: white !important;
}
.select__control{
  border: none !important;
  box-shadow: none !important;
  height: 100% !important;
  min-height: 0px !important;
}
.css-tj5bde-Svg{
  width: 14px;
    color: white;
    
}
span.title-snippte.active {
  background: #321D2E !important;
}
.row-modal{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-content: center;
  align-items: center;
}
.row-modal .basic-single.css-b62m3t-container {
  width: 61% !important;
  border: none !important;
  max-width: 450px;
}
.row-modal .select__control{
  width: 100% !important;
}
.row-modal .select__input{
  color: white !important;
}
.custom-toolbar-example{
  height: 82%;
  max-height: 90%;
}
.text-editor{
  height: 100%;
}
.quill {
  max-height: 90%;
    display: flex;
    height: 90%;
}
.ql-container.ql-snow{
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.ql-editor{
  max-height: 100%;
    /* display: flex; */
    flex-direction: column;
    /* height: 90%; */
    overflow-y: scroll;
}

.modal-container.right .Back-button {
  width: fit-content;
}
.modal-container.right div {
  width: 100%;
}
.container.chat{
  height: 300px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.container.chat .chatfeed-wrapper{
  width: 90%;
}
.container.chat .chat-history{
  min-height: 200px;
  padding: 0px; 
}
aside.wide {
  height: 100%;
}

.container.chat.wide {
  height: 90% !important;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.container.chat.wide .chatfeed-wrapper {
  height: 89%;
}
.wide div#chat-panel {
  display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow: hidden;
    height: 91%;
}
.wide .chat-history {
  max-height: 100% !important;
}
.chat-messages >div>div >div {
  background: transparent !important;
  border: none;
}
.chat-messages p {
  font-size: 13px;
  margin-bottom: 0px;
}


.container.chat.wide{
  display: block;
}

.rowWrite .basic-single.css-b62m3t-container {
  max-width: 200px;
}
.upper-div.grand .select__control{
  width: 180px !important;
}

.modal-container.en  >div {
  display: flex;
  flex-direction: row-reverse;
  /* direction: ltr; */
  gap:10px
}
.modal-container.en label{
  direction: ltr;
}

.en h5 {
  direction: ltr;
}

.modal-container-inside.en > div {
  direction: ltr;
  display: flex;
  gap:20px;
  /* flex-direction: row-reverse; */
}

.modal-container-inside.en *{
  direction: ltr;
}
.chatfeed-wrapper.en * {
  direction: ltr;
}
.toggleLangToright.inside ,.toggleLang.inside {
  position: static;
  margin-right: 40px;
  flex-direction: row-reverse;}


  .AnalyseLang .toggleLang.inside,.AnalyseLang .toggleLangToright.inside {
    margin: 0px;
    flex-direction: row-reverse !important;
    margin-top: 16px;
}

.Two-box-modal .modal-dialog{
  width: 85% !important;
  
  max-width: 100%;
  min-height: 80vh !important;
  
}

.Two-box-modal .modal-body > div{

  padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
}

.Two-box-modal .modal-content{
  background-color: #161523;
  border:1px solid #33323e
}
.two-box-modal .modal-header , .two-box-modal .modal-footer{
  border-color: gray !important;
}

.title-snippte.analyse-done {
  background: #6931a2 !important;
}

.row-button-item-container{
      background: #321d2e !important;
      font-size: 21px;
      justify-content: center;
      padding: 2%;
}

.row-button-item-container .row-button-item{
color:white !important;
font-size: 0.8em;

}
.row-button-item-container .row-button-item:hover{
  color:black !important
  }

  .word-set{
    background:rgb(255, 153, 0,0.5)
  }


  .Two-box-modal.write .modal-dialog {

    width: 98% !important;
    max-width: 100%;
    min-height: 97vh !important;
  }

  .Two-box-modal.write .modal-content{
    height: 86vh;
  }
  .Two-box-modal.write .modal-body > div{
    height: 98%;

  }

  .ql-align-right{
    text-align: right;
    direction: rtl;
  }

  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: -2px;
}



.Big-select-country .basic-single{
  border: 1px solid white !important;
    max-width: 100%;
    height: 100%;
    width: 100%;
    border-radius: 3px;
}

.Big-select-country  .select__control{
  background: #212429;

}